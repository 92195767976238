section#home {
    background-image: url('https://casasonhosfestas.s3.amazonaws.com/imagens/salao2.jpeg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 200px 0;
    max-height: 500px;
    overflow: hidden;
    position: relative;
    /* Adicionado para garantir que o posicionamento do texto funcione corretamente */
}

/* Consulta de mídia para ajustes em dispositivos móveis */
@media screen and (max-width: 768px) {
    section#home {
        padding: 50px 0;
        background-size: auto;
        font-size: 0.7em;
        /* Reduzi ainda mais o padding para dispositivos menores */
    }

    section#home h1 {
        font-size: 1.8em;
    }
}

section#home::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    /* Ajuste o último valor (0.5) para alterar a opacidade */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


section#home h1,
section#home button,
section#home p {
    font-weight: bold;
    position: relative;
    z-index: 1;
    /* Garante que o texto fique na frente do fundo semi-transparente */
}

/* Estilos para o botão WhatsApp */
.whatsapp-btn-home {
    background-color: #25D366;
    /* Verde do WhatsApp */
    color: #fff;
    /* Texto branco */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.whatsapp-btn-home:hover {
    background-color: #128C7E;
    /* Altere a cor de destaque ao passar o mouse se desejar */
}

/* Estilos para o ícone do WhatsApp */
.whatsapp-btn-home i {
    color: #fff;
    /* Ícone branco */
    margin-right: 8px;
}

.img-thumbnail {
    height: 100px !important;
}