.max-width {
    padding: 20px;
    flex-grow: 1;
    /* Faz com que a seção cresça para ocupar o espaço disponível */
    max-width: 1000px;
    /* Nova largura máxima */
    margin: 0 auto;
    /* Centraliza a seção */
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffa600;
    padding: 10px;
}

.brand h4 {
    margin: 0;
    color: #f4f4f4;
}

nav {
    color: #fff;
    text-align: center;
    font-weight: bold;
}

nav a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}

.logo {
    max-width: 100px; /* Garante que a logo não ultrapasse a largura do seu contêiner */
    height: auto; /* Mantém a proporção original da imagem */
  }
  