.decoracao {
    margin-bottom: 30px;
    border: 1px solid #ddd;
    /* Cor da borda */
    border-radius: 5px;
    /* Cantos arredondados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra de fundo */
    padding: 20px;
    /* Espaçamento interno */
}

.imagem-decoracao {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
}

.zoom-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 24px;
    opacity: 0.8;
}

section#themes h5 {
    font-weight: bold;
    text-align: center;
    color: #ffa600;
}

section#themes h2 {
    font-weight: bold;
    text-align: center;
    color: white;
    font-size: 2rem;
    background-color: #ffa600;
    padding: 20px;
    font-style: italic;
}

section#themes .carousel-inner img {
    height: 150px !important;
}

/* Adicione esses estilos ao seu arquivo CSS */

/* Adicione esses estilos ao seu arquivo CSS */

#carouselExampleControls.carousel.slide {
    position: relative !important;
  }
  
  #carouselExampleControls.carousel.slide .carousel-control-prev,
  #carouselExampleControls.carousel.slide .carousel-control-next {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 15% !important; /* Ajuste conforme necessário */
    font-size: 2rem !important;
    color: #ffa600 !important; /* Cor desejada para os botões */
    background-color: transparent !important; /* Fundo transparente para remover o padrão do Bootstrap */
    border: none !important;
    outline: none !important;
    text-align: center !important;
    padding: 10px !important;
    cursor: pointer !important;
  }
  
  #carouselExampleControls.carousel.slide .carousel-control-prev:hover,
  #carouselExampleControls.carousel.slide .carousel-control-next:hover {
    color: #fff !important; /* Cor desejada quando hover nos botões */
  }
  
  #carouselExampleControls.carousel.slide .carousel-control-prev {
    left: 0 !important;
  }
  
  #carouselExampleControls.carousel.slide .carousel-control-next {
    right: 0 !important;
  }

  /* Adicione esses estilos ao seu arquivo CSS */

.carousel-button {
    background-color: #ffa600;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .carousel-button:hover {
    background-color: #ff8000;
  }
  
  .prev-button {
    margin-right: 10px; /* Ajuste o espaçamento entre os botões conforme necessário */
  }
  
  .next-button {
    margin-left: 10px; /* Ajuste o espaçamento entre os botões conforme necessário */
  }
  
  